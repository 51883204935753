import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";

ReactGA.initialize("UA-81626515-2");
ReactGA.pageview(window.location.pathname + window.location.search);

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="camera" element={<App />}>
          <Route path=":cameraId" element={<App />}>
            <Route path="live" element={<App />} />
            <Route path=":year" element={<App />}>
              <Route path=":month" element={<App />}>
                <Route path=":dayOfMonth" element={<App />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
