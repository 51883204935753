export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#091c3b',
    },
    secondary: {
      main: '#ff4f57',
    },
    background: {
      paper: '#f7f7f7',
      default: '#ffffff',
    }
  },
  typography: {
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    button: {
      fontWeight: 1000,
    },
  },
};
