import { createTheme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { themeOptions } from "./themeOptions";

export const theme = createTheme(themeOptions);
export const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      width: 120,
      height: 80,
      marginRight: "10px"
    }
  })
);
