export const API_URL = "https://tapiolakamera.fi/api";
export const DRAWER_WIDTH = 160;

export const MONTH_NAMES = [
  "",
  "Tammikuu",
  "Helmikuu",
  "Maaliskuu",
  "Huhtikuu",
  "Toukokuu",
  "Kesäkuu",
  "Heinäkuu",
  "Elokuu",
  "Syyskuu",
  "Lokakuu",
  "Marraskuu",
  "Joulukuu"
];
