import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useParams, useNavigate } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactGA from "react-ga";
import { API_URL } from "./Config";
import { useLocation } from "react-router-dom";

export default function ImageView(props) {
  const { currentCamera, year, month } = props;
  const { camera_id } = currentCamera;

  let params = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const [images, setImages] = useState(null);
  const [currentDayIndex, setCurrentDayIndex] = useState(null);

  const [{ data, loading, error }] = useAxios(
    `${API_URL}/camera/${camera_id}/year/${year}/month/${month}/images`
  );

  useEffect(() => {
    if (data && data.length > 0) {
      let imageData;
      if (!images) {
        const options = {
          weekday: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZone: currentCamera.timezone
        };

        const dateTimeFormat = new Intl.DateTimeFormat("fi-FI", options);

        imageData = data.map(({ creation_time, url, thumbnail_url }) => {
          return {
            original: url,
            dayOfMonth: new Date(creation_time).getDate(),
            thumbnail: thumbnail_url || url,
            description: dateTimeFormat.format(new Date(creation_time))
          };
        });

        setImages(imageData);
      } else {
        imageData = images;
      }

      const currentIndex = params.dayOfMonth
        ? imageData.findIndex((i) => {
            return i.dayOfMonth === parseInt(params.dayOfMonth, 10);
          })
        : 0;

      if (currentIndex !== currentDayIndex) {
        setCurrentDayIndex(currentIndex);
        const page = `/camera/${camera_id}/${year}/${month}/${imageData[currentIndex].dayOfMonth}`;
        navigate(page);
        ReactGA.pageview(page);
      }
    }
  }, [params, data]);

  if (loading || currentDayIndex === null) {
    return <CircularProgress />;
  }

  if (error) {
    const description = `Virhe! Kuvia ei saatu. Syy: ${error.message}`;
    ReactGA.exception({
      description,
      fatal: true
    });
    return <Alert severity="error">{description}</Alert>;
  }

  console.log("location", location);

  return (
    <Container>
      <ImageGallery
        showIndex={true}
        startIndex={currentDayIndex}
        items={images}
        onScreenChange={(x) => {
          ReactGA.modalview(location);
        }}
        onSlide={(currentIndex) => {
          // console.log("currentIndex", currentIndex);
          if (currentIndex !== currentDayIndex) {
            navigate(
              `/camera/${camera_id}/${year}/${month}/${images[currentIndex].dayOfMonth}`
            );
          }
        }}
      />
    </Container>
  );
}
