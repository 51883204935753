import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import ReactGA from "react-ga";
import "video.js/dist/video-js.css";
import { API_URL, MONTH_NAMES } from "./Config";

export default function MonthList({ currentCamera, year, monthChange }) {
  const { camera_id } = currentCamera;
  const [currentMonthIndex, setMonthIndex] = useState(0);

  let params = useParams();

  const [{ data, loading, error }] = useAxios(
    `${API_URL}/camera/${camera_id}/year/${year}/months`
  );

  useEffect(() => {
    if (data) {
      const month = parseInt(params.month, 10);
      if (!month && data.length > 0) {
        monthChange({ newMonth: data[0], updateNavigation: true });
      } else {
        const monthIdx = data.findIndex((m) => month === m);
        setMonthIndex(monthIdx);
        monthChange({ newMonth: month });
      }
    }
  }, [data, params]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    const description = `Virhe! Kameran ${camera_id} vuoden ${year} kuukausia ei saatu. Syy: ${error.message}`;
    ReactGA.exception({
      description,
      fatal: true
    });
    return <Alert severity="error">{description}</Alert>;
  }

  const handleMonthClick = (_, index) => {
    monthChange({ newMonth: data[index], updateNavigation: true });
  };

  return data.map((month, index) => (
    <ListItem
      button
      selected={currentMonthIndex === index}
      key={`c${camera_id}y${year}m${month}`}
      onClick={(event) => handleMonthClick(event, index)}
    >
      <ListItemText inset secondary={MONTH_NAMES[month]} />
    </ListItem>
  ));
}
