import React, { useEffect } from "react";
import useAxios from "axios-hooks";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Alert from "@mui/material/Alert";
import LinkTab from "@mui/material/Tab";
import Toolbar from "@mui/material/Toolbar";
import CameraView from "./CameraView";
import ReactGA from "react-ga";
import { API_URL, DRAWER_WIDTH } from "./Config";
import logo from "./images/espoo-120x80.png";
import { useStyles } from "./Theme";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function MainView() {
  let params = useParams();
  let navigate = useNavigate();
  const [currentTab, setCurrentTab] = React.useState(null);
  const classes = useStyles();
  const [{ data, loading, error }] = useAxios(`${API_URL}/cameras`);

  useEffect(() => {
    if (!data) {
      return;
    }
    const tabIdx = params.cameraId
      ? data.findIndex((c) => c.camera_id === parseInt(params.cameraId, 10))
      : 0;
    setCurrentTab(tabIdx);
  }, [data, params]);

  if (error) {
    const description = `Virhe! Kameratietoja ei saatu. Syy: ${error.message}`;
    ReactGA.exception({
      description,
      fatal: true
    });
    return <Alert severity="error">{description}</Alert>;
  }

  if (currentTab === null || loading) {
    return <CircularProgress />;
  }

  const handleChange = (e, newValue) => {
    e.preventDefault();
    const page = `/camera/${data[newValue].camera_id}`;
    navigate(page);
    ReactGA.pageview(page);
    setCurrentTab(newValue);
  };

  const tabs = data.map((c) => (
    <LinkTab
      key={`ct_${c.camera_id}`}
      label={c.name}
      href={`/camera/${c.camera_id}`}
      {...a11yProps(c.camera_id)}
    />
  ));

  return (
    <>
      <AppBar position="static" enableColorOnDark elevation={0}>
        <Toolbar sx={{ pr: "24px" }}>
          <img alt="Espoo" src={logo} className={classes.logo} />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="Kamerat"
              indicatorColor="secondary"
              textColor="inherit"
            >
              {tabs}
            </Tabs>
          </Box>
        </Toolbar>
      </AppBar>
      <CameraView key={`cv${currentTab}`} currentCamera={data[currentTab]} />
    </>
  );
}
