import React, { useEffect } from "react";
import useAxios from "axios-hooks";
import { useParams, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import StreamIcon from "@mui/icons-material/Stream";
import ImageView from "./ImageView";
import VREPlayer from "videojs-react-enhanced";
import MonthList from "./MonthList";
import ReactGA from "react-ga";
import "video.js/dist/video-js.css";
import { API_URL, DRAWER_WIDTH } from "./Config";

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export default function CameraView(props) {
  const { currentCamera } = props;
  const { camera_id, stream_uri } = currentCamera;

  let params = useParams();
  let navigate = useNavigate();

  const [currentYearIndex, setYearIndex] = React.useState(-1);
  const [currentMonth, setCurrentMonth] = React.useState(null);
  // const [open, setOpen] = React.useState(true);

  const [{ data, loading, error }] = useAxios(
    `${API_URL}/camera/${camera_id}/years`
  );
  useEffect(() => {
    if (data) {
      const yearIdx = params.year
        ? data.findIndex((y) => y === parseInt(params.year, 10))
        : -1;
      if (currentYearIndex !== yearIdx) {
        setYearIndex(yearIdx);
      }
    }
  }, [data, params]);

  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };
  // const [previousCameraId, setPreviousCameraId] = React.useState(1);

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    const description = `Virhe! Kameran vuosilukutietoja ei saatu. Syy: ${error.message}`;
    ReactGA.exception({
      description,
      fatal: true
    });
    return <Alert severity="error">{description}</Alert>;
  }
  const handleYearClick = (_, index) => {
    setYearIndex(index);
    setCurrentMonth(null);
    if (index === -1) {
      const page = `/camera/${camera_id}`;
      navigate(page);
      ReactGA.pageview(page);
    } else {
      navigate(`/camera/${camera_id}/${data[index]}`);
    }
  };

  const monthChange = ({ newMonth, updateNavigation }) => {
    setCurrentMonth(newMonth);
    if (updateNavigation) {
      navigate(`/camera/${camera_id}/${data[currentYearIndex]}/${newMonth}`);
    }
  };

  const playerOptions = {
    src: currentCamera.stream_uri,
    controls: true,
    autoplay: "play",
    muted: "true",
    preload: "none",
    playsinline: true
  };

  const videojsOptions = {
    fluid: true,
    language: "fi",
    nativeControlsForTouch: true
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="nav" aria-label="Camera Navigation">
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              position: "relative"
            }
          }}
          variant="permanent"
          anchor="left"
          open
        >
          <List>
            {stream_uri && (
              <React.Fragment key={`c${camera_id}live`}>
                <ListItem
                  button
                  selected={currentYearIndex === -1}
                  onClick={(event) => handleYearClick(event, -1)}
                >
                  <ListItemIcon>
                    <StreamIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Livekuva"} />
                </ListItem>
                <Divider />
              </React.Fragment>
            )}
            <Divider />
            {data.map((year, index) => (
              <React.Fragment key={`c${camera_id}>_${year}`}>
                <ListItem
                  button
                  selected={currentYearIndex === index}
                  key={`c${camera_id}y${year}`}
                  onClick={(event) => handleYearClick(event, index)}
                >
                  <ListItemIcon>
                    <PhotoLibraryIcon />
                  </ListItemIcon>
                  <ListItemText primary={year} />
                </ListItem>
                {currentYearIndex === index ? (
                  <MonthList
                    key={`ml${index}`}
                    currentCamera={currentCamera}
                    year={data[currentYearIndex]}
                    monthChange={monthChange}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
      </Box>
      <Main open={true}>
        {currentYearIndex === -1 ? (
          <VREPlayer
            playerOptions={playerOptions}
            videojsOptions={videojsOptions}
            hideList={[
              "volumePanel",
              "pictureInPictureToggle",
              "progressControl",
              "remainingTimeDisplay"
            ]}
            onReady={(_) => {
              ReactGA.event({
                category: "Live Video",
                action: "Player Ready"
              });
            }}
            // onPlay={(e, _, second) => console.log("Play!")}
            // onPause={(e, _, second) => console.log("Pause!")}
            // onEnded={(e, _) => console.log("Ended!")}
            onError={(_, player) => {
              const error = player.error();
              ReactGA.exception({
                description: `Video playback error, camera ${camera_id}, error: ${error.message}`,
                fatal: true
              });
            }}
          />
        ) : null}
        {currentYearIndex >= 0 && currentMonth ? (
          <ImageView
            key={`iv${currentCamera}_${currentYearIndex}_${currentMonth}}`}
            currentCamera={currentCamera}
            year={data[currentYearIndex]}
            month={currentMonth}
          />
        ) : null}
      </Main>
    </Box>
  );
}
